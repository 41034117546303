import { Tile } from "./tile";

export class TileRenderer {

  tileTemplate: string;

  constructor(tile: Tile) {
    this.tileTemplate = `
<div class="tile tile-article">
  <a href="${tile.link}">
    <div class="${tile.sticky ? 'article-sticky' : ''}">
      <div class="article-img">
        <img src="${tile.image}" alt="immagine articolo" />
      </div>
      <div class="article-title-box">
        <div><span class="article-title">${tile.title}</span></div>
        <div class="article-info">
          ${new Date(tile.date).toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric' })}
        </div>
      </div>
    </div>
  </a>
</div>`
  }

}