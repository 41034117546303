import '../css/layout.css';
import { DataLoader } from "./data-loader"

async function onReady(): Promise<void> {
    if (document.readyState !== 'loading') {
        return;
    }
    await new Promise((resolve) => {
        document.addEventListener('DOMContentLoaded', resolve);
    });
}
(async () => {
    await onReady();
    await DataLoader.initializeTiles();
    await DataLoader.initializeUpdates();
})();