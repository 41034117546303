import { Tile } from "./tile";
import { TileRenderer } from "./tile-renderer";
import { Update } from "./update";
import { UpdateRenderer } from "./update-renderer";

const baseUrl = 'https://www.windowsblogitalia.com/wp-json/wbi';
const dataTilesContainerAttributeName = 'data-tiles-container';
const dataTilesSearchAttributeName = 'data-tiles-search';
const dataTilesCategoryAttributeName = 'data-tiles-category';
const dataTilesTagAttributeName = 'data-tiles-tag';
const dataTilesIsLoadingAttributeName = 'data-tiles-is-loading';
const dataTilesPageAttributeName = 'data-tiles-page';
const dateUpdatedContainerAttributeName = 'data-updates-container';

export abstract class DataLoader {

    public static async initializeTiles() {
        document.addEventListener("scroll", function (event) {
            DataLoader.loadMoreTiles();
        });

        const tileContainers = document.querySelectorAll(`[${dataTilesContainerAttributeName}]`);
        tileContainers.forEach(tileContainer => {
            this.loadTiles(tileContainer);
        });
    }

    public static async loadMoreTiles() {
        const vHeight = window.innerHeight;
        const tileContainers = document.querySelectorAll(`[${dataTilesContainerAttributeName}]`);
        tileContainers.forEach(tileContainer => {
            if (tileContainer.childElementCount > 0) {
                const lastChildRect = tileContainer.lastElementChild.getBoundingClientRect();
                if (lastChildRect.top < vHeight) {
                    DataLoader.loadTiles(tileContainer);
                }
            }
        });
    }

    public static async loadTiles(tileContainer: Element) {
        if (tileContainer.hasAttribute(dataTilesIsLoadingAttributeName)) {
            // tiles are already loading... wait before new request
            return;
        }
        tileContainer.setAttribute(dataTilesIsLoadingAttributeName, 'true');

        let page = 1;
        if (tileContainer.hasAttribute(dataTilesPageAttributeName)) {
            page = +tileContainer.getAttribute(dataTilesPageAttributeName);
        }

        let url = `${baseUrl}/v1/tiles?page=${page}`;
        if (tileContainer.hasAttribute(dataTilesSearchAttributeName)) {
            // is search: parse query string
            const searchQuery = tileContainer.getAttribute(dataTilesSearchAttributeName);
            url += `&s=${searchQuery}`;
        } else if (tileContainer.hasAttribute(dataTilesCategoryAttributeName)) {
            // is category page: parse category id
            const categoryId = tileContainer.getAttribute(dataTilesCategoryAttributeName);
            url += `&cat=${categoryId}`;
        } else if (tileContainer.hasAttribute(dataTilesTagAttributeName)) {
            // is tag page: parse tag id
            const tagId = tileContainer.getAttribute(dataTilesTagAttributeName);
            url += `&tag=${tagId}`;
        } else {
            // is home page
        }

        const tilesResponse = await fetch(url, {
            method: 'GET',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            }
        }).then((res) => responseHandler(res));

        const fetchedTilesArray = (tilesResponse as TilesResponse).posts;
        fetchedTilesArray.forEach(fetchedTile => {
            const tileRenderer = new TileRenderer(fetchedTile);
            tileContainer.innerHTML = tileContainer.innerHTML + tileRenderer.tileTemplate;
        });

        tileContainer.removeAttribute(dataTilesIsLoadingAttributeName);
        tileContainer.setAttribute(dataTilesPageAttributeName, (++page).toString());

        this.loadMoreTiles();
    }

    public static async initializeUpdates() {
        const updatesContainers = document.querySelectorAll(`[${dateUpdatedContainerAttributeName}]`);
        updatesContainers.forEach(updatesContainer => {
            this.loadUpdates(updatesContainer);
        });
    }

    public static async loadUpdates(updatesContainer: Element) {
        let url = `${baseUrl}/v1/updates`;

        const updatesResponse = await fetch(url, {
            method: 'GET',
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            }
        }).then((res) => responseHandler(res));

        const fetchedUpdatesArray = (updatesResponse as UpdatesResponse).posts;
        fetchedUpdatesArray.forEach(fetchedUpdate => {
            const updateRenderer = new UpdateRenderer(fetchedUpdate);
            updatesContainer.innerHTML = updatesContainer.innerHTML + updateRenderer.updateTemplate;
        });
    }
}

interface TilesResponse {
    next: string,
    posts: Array<Tile>
}

interface UpdatesResponse {
    next: string,
    posts: Array<Update>
}

type TileResponse =
    | (Omit<Response, "json"> & {
        status: 200;
        json: () => TilesResponse | PromiseLike<TilesResponse>;
    });

type UpdateResponse =
    | (Omit<Response, "json"> & {
        status: 200;
        json: () => UpdatesResponse | PromiseLike<UpdatesResponse>;
    });

const marshalResponse = (res: TileResponse) => {
    if (res.status === 200) return res.json();
    return Error("Unhandled response code: " + res.status);
};

const marshalUpdatesResponse = (res: UpdateResponse) => {
    if (res.status === 200) return res.json();
    return Error("Unhandled response code: " + res.status);
};

const responseHandler = (response: Response) => {
    const res = response as TileResponse;
    return marshalResponse(res);
};

const updatesResponseHandler = (response: Response) => {
    const res = response as UpdateResponse;
    return marshalUpdatesResponse(res);
};
