import { Update } from "./update";

export class UpdateRenderer {

  updateTemplate: string;

  constructor(update: Update) {
    this.updateTemplate = `
<a href="${update.link}" target="_blank">
  <div class="box-update-container">
    <div class="box-update-img">
      <img layout="fixed" width="75" height="75" src="${update.image}" />
    </div>
    <div class="box-update-categoryicons">
      ${update.isWindows ?
        '<img layout="fixed" width="18" height="18" src="https://www.windowsblogitalia.com/wp-content/themes/windows-blog-italia/images/box-update-categoryicon-windowslogo.svg" class="box-update-categoryicon" />' : ''
      }
      ${update.isPc ?
        '<img layout="fixed" width="18" height="18" src="https://www.windowsblogitalia.com/wp-content/themes/windows-blog-italia/images/box-update-categoryicon-pc.svg" class="box-update-categoryicon" />' : ''
      }
      ${update.isMobile ?
        '<img layout="fixed" width="18" height="18" src="https://www.windowsblogitalia.com/wp-content/themes/windows-blog-italia/images/box-update-categoryicon-mobile.svg" class="box-update-categoryicon" />' : ''
      }
      ${update.isAndroid ?
        '<img layout="fixed" width="18" height="18" src="https://www.windowsblogitalia.com/wp-content/themes/windows-blog-italia/images/box-update-categoryicon-android.svg" class="box-update-categoryicon" />' : ''
      }
      ${update.isApple ?
        '<img layout="fixed" width="18" height="18" src="https://www.windowsblogitalia.com/wp-content/themes/windows-blog-italia/images/box-update-categoryicon-apple.svg" class="box-update-categoryicon" />' : ''
      }
    </div>
    <div class="box-update-title">
      <span>${update.title}</span>	
    </div>
  </div>
</a>`
  }

}